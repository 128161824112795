<template>
    <div class="weatherlayout_wrap">
        <div class="setting_weather_wrap">
            <!-- <button class="weather_setting_btn weather_setting_btn2" @click="iconIsOpen = !iconIsOpen">날씨아이콘세팅 (임시)</button>
      <button class="weather_setting_btn" @click="isOpen = !isOpen">날씨세팅(임시)</button> -->
            <ul class="weather_select_list" v-if="isOpen">
                <li class="weather_select_list_li">
                    <input type="radio" id="weather1" value="1" v-model="manualSetting">
                    <label for="weather1" class="weather_select_list_item">아침-맑음</label>
                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather2" value="2" v-model="manualSetting">
                    <label for="weather2" class="weather_select_list_item">아침-구름많음</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather3" value="3" v-model="manualSetting">
                    <label for="weather3" class="weather_select_list_item">아침-비</label>
                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather4" value="4" v-model="manualSetting">
                    <label for="weather4" class="weather_select_list_item">아침-번개</label>
                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather5" value="5" v-model="manualSetting">
                    <label for="weather5" class="weather_select_list_item">아침-눈</label>
                </li>

                <li class="weather_select_list_li">
                    <input type="radio" id="weather6" value="6" v-model="manualSetting">
                    <label for="weather6" class="weather_select_list_item">낮-맑음</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather7" value="7" v-model="manualSetting">
                    <label for="weather7" class="weather_select_list_item">낮-구름많음</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather8" value="8" v-model="manualSetting">
                    <label for="weather8" class="weather_select_list_item">낮-비</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather9" value="9" v-model="manualSetting">
                    <label for="weather9" class="weather_select_list_item">낮-번개</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather10" value="10" v-model="manualSetting">
                    <label for="weather10" class="weather_select_list_item">낮-눈</label>

                </li>

                <li class="weather_select_list_li">
                    <input type="radio" id="weather11" value="11" v-model="manualSetting">
                    <label for="weather11" class="weather_select_list_item">저녁-맑음</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather12" value="12" v-model="manualSetting">
                    <label for="weather12" class="weather_select_list_item">저녁-구름많음</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather13" value="13" v-model="manualSetting">
                    <label for="weather13" class="weather_select_list_item">저녁-비</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather14" value="14" v-model="manualSetting">
                    <label for="weather14" class="weather_select_list_item">저녁-번개</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather15" value="15" v-model="manualSetting">
                    <label for="weather15" class="weather_select_list_item">저녁-눈</label>

                </li>

                <li class="weather_select_list_li">
                    <input type="radio" id="weather16" value="16" v-model="manualSetting">
                    <label for="weather16" class="weather_select_list_item">밤-아침</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather17" value="17" v-model="manualSetting">
                    <label for="weather17" class="weather_select_list_item">밤-구름많음</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather18" value="18" v-model="manualSetting">
                    <label for="weather18" class="weather_select_list_item">밤-비</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather19" value="19" v-model="manualSetting">
                    <label for="weather19" class="weather_select_list_item">밤-번개</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="weather20" value="20" v-model="manualSetting">
                    <label for="weather20" class="weather_select_list_item">밤-눈</label>

                </li>
            </ul>
            <ul class="weather_select_list weather_select_list_icon" v-if="iconIsOpen">
                <li class="weather_select_list_li">
                    <input type="radio" id="icon1" value="clear_sky" v-model="iconSetting">
                    <label for="icon1" class="weather_select_list_item">Clear Sky</label>
                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="icon2" value="few_clouds" v-model="iconSetting">
                    <label for="icon2" class="weather_select_list_item">Few clouds</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="icon3" value="scattered_clouds" v-model="iconSetting">
                    <label for="icon3" class="weather_select_list_item">scattered clouds</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="icon4" value="rain" v-model="iconSetting">
                    <label for="icon4" class="weather_select_list_item">rain</label>
                </li>

                <li class="weather_select_list_li">
                    <input type="radio" id="icon5" value="thunderstorm" v-model="iconSetting">
                    <label for="icon5" class="weather_select_list_item">thunderstorm</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="icon6" value="snow" v-model="iconSetting">
                    <label for="icon6" class="weather_select_list_item">snow</label>

                </li>
                <li class="weather_select_list_li">
                    <input type="radio" id="icon7" value="mist" v-model="iconSetting">
                    <label for="icon7" class="weather_select_list_item">mist</label>

                </li>
            </ul>
        </div>
        <!--===================== 비 =====================-->
        <canvas v-show="isRain" width='1280' height='720' id="cesraincanvas"></canvas>
        <canvas v-show="isThunder" width='1280' height='720' id="cesthundercanvas"></canvas>
        <router-view :id="weatherLayoutId" class="weatherlayout" />
        <!--===================== 맑음 =====================-->
        <!-- 아침,낮,저녁 - 맑음,눈-->
        <img v-if="getCloud === 'morining_cloud'" class="weatherlayout_cloud weatherlayout_cloud1"
             src="/media/img/morning_cloud2.png" alt="cloud1">
        <!-- 밤 - 맑음,눈 -->
        <img v-if="getCloud === 'night_cloud'" class="weatherlayout_cloud weatherlayout_cloud1"
             src="/media/img/night_cloud_mo.png" alt="cloud1">

        <!--===================== 구름많음 =====================-->
        <!-- 아침 구름 4개-->
        <img v-if="getCloudyCloud === 'morning_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud1"
             src="/media/img/cloud_morning.svg">
        <img v-if="getCloudyCloud === 'morning_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud2"
             src="/media/img/cloud_morning.svg">
        <img v-if="getCloudyCloud === 'morning_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud3"
             src="/media/img/cloud_morning.svg">
        <img v-if="getCloudyCloud === 'morning_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud4"
             src="/media/img/cloud_morning.svg">
        <!-- 낮 구름 4개-->
        <img v-if="getCloudyCloud === 'afternoon_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud1"
             src="/media/img/cloud_afternoon.svg">
        <img v-if="getCloudyCloud === 'afternoon_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud2"
             src="/media/img/cloud_afternoon.svg">
        <img v-if="getCloudyCloud === 'afternoon_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud3"
             src="/media/img/cloud_afternoon.svg">
        <img v-if="getCloudyCloud === 'afternoon_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud4"
             src="/media/img/cloud_afternoon.svg">
        <!-- 저녁 구름 4개-->
        <img v-if="getCloudyCloud === 'dinner_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud1"
             src="/media/img/cloud_afternoon.svg">
        <img v-if="getCloudyCloud === 'dinner_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud2"
             src="/media/img/cloud_afternoon.svg">
        <img v-if="getCloudyCloud === 'dinner_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud3"
             src="/media/img/cloud_afternoon.svg">
        <img v-if="getCloudyCloud === 'dinner_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud4"
             src="/media/img/cloud_afternoon.svg">
        <!-- 밤 구름 4개-->
        <img v-if="getCloudyCloud === 'night_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud1"
             src="/media/img/cloud_eve.svg">
        <img v-if="getCloudyCloud === 'night_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud2"
             src="/media/img/cloud_eve.svg">
        <img v-if="getCloudyCloud === 'night_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud3"
             src="/media/img/cloud_eve.svg">
        <img v-if="getCloudyCloud === 'night_cloudy_cloud'" class="weatherlayout_cloud cloudy_cloud4"
             src="/media/img/cloud_eve.svg">

        <!--===================== 눈 =====================-->
        <particles-bg v-if="isSnow" id="particlesjs" type="custom" :config="config" :bg="true" />
    </div>
</template>
<script>
import { cesrainAnimation } from "@/assets/js/cesRainAnimation";
import { cesthunderAnimation } from "@/assets/js/cesThunderAnimation";
import { ParticlesBg } from "particles-bg-vue";
import { getCurrentWeather } from '@/service/api/weatherApi';
export default {
  name: 'LayoutWeather',
  components: {
    'particles-bg': ParticlesBg
  },
  data () {
    return {
      manualSetting: -1,
      iconSetting: '',
      isOpen: false,
      iconIsOpen: false,
      config: {
        num: 1,
        rps: -1,
        radius: [15, 15],
        life: [10, 10],
        v: 0.1,
        tha: [150, 200],
        alpha: [1, 1],
        scale: [0.1, 0.4],
        position: "all",
        cross: "dead",
        random: 10,
        color: "#ffffff",
        g: 0.5
      }
    };
  },
  created () {
  },

  mounted () {
    this.changeWeather();
  },

  methods: {
    changeWeather () {
      this.$nextTick(() => {
        cesrainAnimation.init();
        cesrainAnimation.animate();
        cesthunderAnimation.init();
        cesthunderAnimation.animate();
        window.addEventListener('resize', cesrainAnimation.resize.bind(cesrainAnimation));
        window.addEventListener('resize', cesthunderAnimation.resize.bind(cesthunderAnimation));
      });
    },
    async setLasVegas () {
      // 라스베가스 위도 : 36.2277, 경도 : -115.264
      const result = await getCurrentWeather(36.2277, -115.264);
      const { result: weatherResult } = result.data;
      this.$store.commit("cesweather/setIntervalIcon", weatherResult.icon);
      this.$store.commit("cesweather/setOpenWeatherInfo", weatherResult);
    },
    setCurrentPosition () {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(this.successCallBackGeolocation, this.failCallBackGeolocation);
      }
    },
    successCallBackGeolocation (position) {
      const { coords } = position;
      const { latitude, longitude } = coords;
      this.$store.commit("cesweather/setgeolocationInfo", {
        latitude,
        longitude
      });
      this.settingWeatherInfo();
    },
    failCallBackGeolocation () {
      this.$store.commit("cesweather/setgeolocationInfo", {
        latitude: 36.2277,
        longitude: -115.264
      });
      this.settingWeatherInfo();
    },
    async settingWeatherInfo () {
      const result = await getCurrentWeather(this.$store.getters["cesweather/getGeolocation"].latitude, this.$store.getters["cesweather/getGeolocation"].longitude);
      const { result: weatherResult } = result.data;
      this.$store.commit("cesweather/setIntervalIcon", weatherResult.icon);
      this.$store.commit("cesweather/setOpenWeatherInfo", weatherResult);
    }
  },

  computed: {
    weatherLayoutId () {
      return this.$store.getters["cesweather/getWeaterTimeCssId"];
    },
    getCloud () {
      // 아침,낮,저녁 - 맑음,눈 : morining_cloud
      // 밤 - 맑음,눈 : night_cloud;
      if (this.$store.getters["cesweather/getWeatherInfo"].currentTimes === "밤" && (this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 1 || this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 5)) {
        return "night_cloud";
      } else if (this.$store.getters["cesweather/getWeatherInfo"].currentTimes === "아침" && (this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 1 || this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 5)) {
        return "morining_cloud";
      } else if (this.$store.getters["cesweather/getWeatherInfo"].currentTimes === "낮" && (this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 1 || this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 5)) {
        return "morining_cloud";
      } else if (this.$store.getters["cesweather/getWeatherInfo"].currentTimes === "저녁" && (this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 1 || this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 5)) {
        return "morining_cloud";
      }

      return 'x';
    },
    getCloudyCloud () {
      if (this.$store.getters["cesweather/getWeatherInfo"].currentTimes === '아침' && (this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 2 || this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 3 || this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 4)) {
        return "morning_cloudy_cloud";
      } else if (this.$store.getters["cesweather/getWeatherInfo"].currentTimes === '낮' && (this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 2 || this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 3 || this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 4)) {
        return "afternoon_cloudy_cloud";
      } else if (this.$store.getters["cesweather/getWeatherInfo"].currentTimes === '저녁' && (this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 2 || this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 3 || this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 4)) {
        return "dinner_cloudy_cloud";
      } else if (this.$store.getters["cesweather/getWeatherInfo"].currentTimes === '밤' && (this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 2 || this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 3 || this.$store.getters["cesweather/getWeatherInfo"].currentWeather === 4)) {
        return "night_cloudy_cloud";
      }

      return 'x';
    },
    isRain () {
      if (this.$store.getters['cesweather/getWeatherInfo'].currentWeather === 3) {
        return true;
      }

      return false;
    },
    isThunder () {
      if (this.$store.getters['cesweather/getWeatherInfo'].currentWeather === 4) {
        return true;
      }
      return false;
    },
    isSnow () {
      if (this.$store.getters['cesweather/getWeatherInfo'].currentWeather === 5) {
        return true;
      }

      return false;
    }
  },
  watch: {
    iconSetting (val) {
      this.$store.commit("cesweather/setWeatherIcon", val);
    },
    manualSetting (val) {
      if (parseInt(parseInt(val)) === 1) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '아침',
          currentWeather: 1
        });
      } else if (parseInt(val) === 2) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '아침',
          currentWeather: 2
        });
      } else if (parseInt(val) === 3) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '아침',
          currentWeather: 3
        });
      } else if (parseInt(val) === 4) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '아침',
          currentWeather: 4
        });
      } else if (parseInt(val) === 5) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '아침',
          currentWeather: 5
        });
      } else if (parseInt(val) === 6) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '낮',
          currentWeather: 1
        });
      } else if (parseInt(val) === 7) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '낮',
          currentWeather: 2
        });
      } else if (parseInt(val) === 8) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '낮',
          currentWeather: 3
        });
      } else if (parseInt(val) === 9) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '낮',
          currentWeather: 4
        });
      } else if (parseInt(val) === 10) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '낮',
          currentWeather: 5
        });
      } else if (parseInt(val) === 11) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '저녁',
          currentWeather: 1
        });
      } else if (parseInt(val) === 12) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '저녁',
          currentWeather: 2
        });
      } else if (parseInt(val) === 13) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '저녁',
          currentWeather: 3
        });
      } else if (parseInt(val) === 14) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '저녁',
          currentWeather: 4
        });
      } else if (parseInt(val) === 15) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '저녁',
          currentWeather: 5
        });
      } else if (parseInt(val) === 16) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '밤',
          currentWeather: 1
        });
      } else if (parseInt(val) === 17) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '밤',
          currentWeather: 2
        });
      } else if (parseInt(val) === 18) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '밤',
          currentWeather: 3
        });
      } else if (parseInt(val) === 19) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '밤',
          currentWeather: 4
        });
      } else if (parseInt(val) === 20) {
        this.$store.commit("cesweather/setWeatherInfo", {
          currentTimes: '밤',
          currentWeather: 5
        });
      }
    }
  },
  destroyed () {
    clearInterval(this.timeInterval);
  }
};
</script>
<style scoped src="@/assets/css/ces/weather/weather.css"></style>
