/**
 * @description 날씨 애니매이션 ( https://codepen.io/faisal-jawed/pen/LYPMpNE 참조)
 * @author CHOI DAE GEON
 */
const cesthunderAnimation = {
  canvas: null,
  thundercanvas: null,
  ctx: null,
  stgw: 1920,
  stgh: 900,
  count: 180,
  lcount: 2,
  layer: [],
  layery: [],
  rafID: -1,
  stt: 0,
  str: Date.now() + Math.random() * 4000,
  stact: false,
  /**
   * @description 캔버스 Element 세팅
   * @author CHOI DAE GEON
   */
  setCanvas () {
    this.canvas = document.querySelector('#cesthundercanvas');
  },
  /**
   * @description Context Element 세팅
   * @author CHOI DAE GEON
   */
  setContext () {
    this.ctx = this.canvas.getContext('2d');
  },
  /**
   * @description 캔버스 크기
   * @author CHOI DAE GEON
   */
  pexresize () {
    const cw = window.innerWidth;
    const ch = window.innerHeight;
    this.canvas.style.width = cw + "px";
    this.canvas.style.height = ch + "px";
  },
  /**
   * @description pushLayer
   * @author CHOI DAE GEON
   */
  pushLayer () {
    this.layer = [];
    this.layery = [];
    this.ctx.fillStyle = "rgba(255,255,255,0.5)";
    for (let l = 0; l < this.lcount; l++) {
      for (let i = 0; i < this.count * (this.lcount - l) / 1.5; i++) {
        const myx = Math.floor(Math.random() * this.stgw);
        const myy = Math.floor(Math.random() * this.stgh);
        const myh = l * 6 + 8;
        const myw = myh / 10;
        this.ctx.beginPath();
        this.ctx.moveTo(myx, myy);
        this.ctx.lineTo(myx + myw, myy + myh);
        this.ctx.arc(myx, myy + myh, myw, 0, 1 * Math.PI);
        this.ctx.lineTo(myx - myw, myy + myh);
        this.ctx.closePath();
        this.ctx.fill();
      }
      this.layer[l] = new Image();
      this.layer[l].src = this.canvas.toDataURL("image/png");
      this.layery[l] = 0;
    }
  },
  /**
  * @description 애니메이션
  * @author CHOI DAE GEON
  */
  animate () {
    let ex = -1;
    if (this.rafID > -1) {
      this.stopAnimate();
    }
    this.ctx.clearRect(0, 0, this.stgw, this.stgh);
    for (let l = 0; l < this.lcount; l++) {
      this.layery[l] += (l + 1.5) * 5;
      if (this.layery[l] > this.stgh) {
        this.layery[l] = this.layery[l] - this.stgh;
      }
      this.ctx.drawImage(this.layer[l], 0, this.layery[l]);
      this.ctx.drawImage(this.layer[l], 0, this.layery[l] - this.stgh);
    }

    if (Date.now() > this.str) {
      this.stact = true;
    }

    if (this.stact) {
      this.stt++;
      if (this.stt < 5 + Math.random() * 10) {
        ex = this.stt / 30;
      } else {
        ex = (this.stt - 10) / 30;
      }

      if (this.stt > 20) {
        this.stt = 0;
        this.stact = false;
        this.str = Date.now() + Math.random() * 8000 + 2000;
      }

      this.ctx.fillStyle = "rgba(255,255,255," + ex + ")";
      this.ctx.fillRect(0, 0, this.stgw, this.stgh);
    }

    this.rafID = requestAnimationFrame(this.animate.bind(this));
  },
  /**
   * @description 애니메이션 stop
   * @author CHOI DAE GEON
   */
  stopAnimate () {
    cancelAnimationFrame(this.rafID);
  },
  /**
  * @description init
  * @author CHOI DAE GEON
  */
  init () {
    if (this.canvas && this.ctx) {
      this.clearCanvas();
    }
    this.setCanvas();
    this.setContext();
    this.pexresize();
    this.pushLayer();
  },
  /**
  * @description resize
  * @author CHOI DAE GEON
  */
  resize () {
    this.pexresize();
  },
  /**
  * @description clear
  * @author CHOI DAE GEON
  */
  clearCanvas () {
    // 픽셀 정리
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    // 컨텍스트 리셋
    this.ctx.beginPath();
  },
  /**
   * @description 캔버스 Element return
   * @returns Canvas Element
   */
  getCanvas () {
    return this.canvas;
  },
  /**
   * @description Canvas.getContext('2d') return
   * @returns Canvas.getContext('2d')
   */
  getContext () {
    return this.ctx;
  }
};

export { cesthunderAnimation };
